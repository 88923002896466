import { Box, Divider } from "@mui/material";
import React from "react";
import {
  CUSTOM_FORM_STATUS_NEW_ID,
  CUSTOM_FORM_STATUS_DRAFT_ID,
} from "../../shared/resources/referenceConstants";
import AppointmentsParent from "../appointments/AppointmentsParent";
import { useAppSelector } from "../../state/hooks";
import {
  selectPermissions,
  selectSelectedClient,
} from "../../state/slices/access";
import DocumentsList from "../documents/documentList/DocumentsList";
import { defaultNullUndefined } from "../../shared/globalFunctions";
import GeneratedPDFParent, {
  generateClientDocumentUrl,
} from "../documents/pdfGeneration/GeneratedPDFParent";

export default function LandingPage() {
  const permissions = useAppSelector(selectPermissions);
  const client = useAppSelector(selectSelectedClient);
  const clientId = defaultNullUndefined(client?.clientId, 0);
  const [isViewDocument, setIsViewDocument] = React.useState<boolean>(false);
  const [documentURL, setDocumentURL] = React.useState<string>("");

  const handleViewDocument = (id: number) => {
    setIsViewDocument(true);
    setDocumentURL(generateClientDocumentUrl(clientId, id));
  };

  const handleCloseViewDocument = () => {
    setDocumentURL("");
    setIsViewDocument(false);
  };

  return (
    <Box>
      {permissions?.can_manage_documents && (
        <>
          <DocumentsList
            clientId={clientId}
            formResponseStatusIds={[
              CUSTOM_FORM_STATUS_NEW_ID,
              CUSTOM_FORM_STATUS_DRAFT_ID,
            ]}
            onViewDocument={handleViewDocument}
          />
          <Divider />
        </>
      )}
      <br />
      <AppointmentsParent />
      {isViewDocument && (
        <GeneratedPDFParent
          onCancel={handleCloseViewDocument}
          showDialog={true}
          documentURL={documentURL}
        />
      )}
    </Box>
  );
}
