import {
  Box,
  LinearProgress,
  Typography,
  Tab,
  Tabs,
  Grid,
} from "@mui/material";
import React from "react";
import { useAppSelector } from "../../state/hooks";
import { selectSelectedClient } from "../../state/slices/access";
import { parseIntSafe } from "../../shared/globalFunctions";
import TabPanel from "../../shared/ReusableComponents/TabPanel";
import AppointmentTableParent from "./AppointmentTableParent";

export default function AppointmentsParent() {
  const client = useAppSelector(selectSelectedClient);
  const clientId: number = parseIntSafe(client?.clientId, 0);
  const [tabIndex, setTabIndex] = React.useState<number>(0);

  /* --- Handlers ---*/
  const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex);
  };

  if (client === undefined || client === null || clientId === 0) {
    return <LinearProgress />;
  } else {
    return (
      <Box>
        <Grid container direction="row">
          <Grid xs={1} item></Grid>
          <Grid xs={10} item>
            <Typography variant="h3">Appointments</Typography>
            <Tabs value={tabIndex} onChange={handleTabChange}>
              <Tab label="Upcoming Appointments" />
              <Tab label="Past Appointments" />
            </Tabs>
            <TabPanel value={tabIndex} index={0} padding={0}>
              <AppointmentTableParent clientId={clientId} isPast={false} />
            </TabPanel>
            <TabPanel value={tabIndex} index={1} padding={0}>
              <AppointmentTableParent clientId={clientId} isPast={true} />
            </TabPanel>
          </Grid>
          <Grid xs={1} item></Grid>
        </Grid>
      </Box>
    );
  }
}
