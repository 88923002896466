/* eslint-disable no-empty-pattern */
import React from "react";

/* --- MUI Imports --- */
import { Box, Grid, Typography } from "@mui/material";

/* --- Project Imports --- */

import { useAppSelector } from "../../state/hooks";
import { defaultNullUndefined } from "../../shared/globalFunctions";
import { selectSelectedClient } from "../../state/slices/access";
import ClientDocumentParent from "./uploadDocuments/ClientDocumentParent";
import GeneratedPDFParent from "./pdfGeneration/GeneratedPDFParent";
import { buildAwsDownloadLink } from "../../apis/internalDb/awsFiles/files";

interface DocumentsParentProps {
  formResponseStatusIds: number[];
  showHeader: boolean;
}

export default function DocumentsParent({
  formResponseStatusIds,
  showHeader,
}: DocumentsParentProps) {
  const client = useAppSelector(selectSelectedClient);
  const clientId = defaultNullUndefined(client?.clientId, 0);
  const [documentId, setDocumentId] = React.useState<number>(0);
  const [isViewAWSDocument, setIsViewAWSDocument] =
    React.useState<boolean>(false);
  /* Document Handlers */

  const handleViewAWSDocument = (id: number) => {
    setDocumentId(id);
    setIsViewAWSDocument(true);
  };

  const handleCloseViewDocument = () => {
    setDocumentId(0);
    setIsViewAWSDocument(false);
  };

  return (
    <Box>
      <Grid container direction="row">
        <Grid xs={1} item></Grid>
        <Grid xs={10} item>
          {showHeader && <Typography variant="h3">Documents</Typography>}
          <ClientDocumentParent onViewDocument={handleViewAWSDocument} />
          {isViewAWSDocument && (
            <GeneratedPDFParent
              onCancel={handleCloseViewDocument}
              showDialog={true}
              documentURL={buildAwsDownloadLink(
                documentId.toString(),
                clientId,
                false
              )}
            />
          )}
        </Grid>
        <Grid xs={1} item></Grid>
      </Grid>
    </Box>
  );
}
