/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import {
  IconButton,
  Box,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadIcon from "@mui/icons-material/Download";
import { useQuery } from "react-query";
import {
  ClientDocumentListType,
  getDocumentsByClientId,
} from "../../../apis/internalDb/documentRoutes";
import { clientDocumentKeys } from "../../../apis/internalDb/keyFactory";
import { defaultNullUndefined } from "../../../shared/globalFunctions";
import { buildAwsDownloadLink } from "../../../apis/internalDb/awsFiles/files";
import DisplayDocumentStatus from "../documentList/DisplayDocumentStatus";

import { Link } from "react-router-dom";
import {
  FormFieldResponseType,
  FormResponseDataset,
  clientPortalFormKeys,
  getFormResponseByClientId,
} from "../../../apis/internalDb/forms/forms";
import {
  CUSTOM_FORM_STATUS_SUBMIT_ID,
  CUSTOM_FORM_STATUS_NEW_ID,
  CUSTOM_FORM_STATUS_DRAFT_ID,
} from "../../../shared/resources/referenceConstants";

interface ClientDocumentDataTableProps {
  clientId: number;
  isArchived: boolean;
  onViewDocument: Function;
}

export interface CombinedFormDocumentType {
  row_id: string;
  // From FormResponseType
  custom_form_response_id: number | null;
  form_name: string | null;
  custom_form_field_response: FormFieldResponseType[] | null;
  is_draft: boolean | null;
  status_id: number | null;

  // From ClientDocumentListType
  document_id: number | null;
  client_id: number | null;
  display_name: string | null;
  comment: string | null;
  doc_type_ref_id: number | null;
  created_by: string | null;
  created_on: Date | null;
  reference: {
    label: string | null;
  };
  isDocument: boolean;
}

export default function ClientDocumentDataTable({
  clientId,
  isArchived,
  onViewDocument,
}: ClientDocumentDataTableProps) {
  const PAGE_SIZE = [15, 50, 100];
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: PAGE_SIZE[0],
  });
  const [startIndex, setStartIndex] = React.useState(0);
  const [batchSize, setBatchSize] = React.useState(10);
  const [sortField, setSortField] = React.useState<string | undefined>(
    undefined
  );
  const [sortDirection, setSortDirection] = React.useState<string | undefined>(
    undefined
  );

  const { isLoading, data: documentData } = useQuery<
    ClientDocumentListType[],
    Error
  >(clientDocumentKeys.list(clientId, startIndex, batchSize), () =>
    getDocumentsByClientId(clientId)
  );

  const {
    isLoading: formsLoading,
    isError: formsError,
    data: formsData,
  } = useQuery<FormResponseDataset, Error>(
    clientPortalFormKeys.responseTable(
      { sortField, sortDirection, ...paginationModel },
      clientId,
      []
    ),
    () =>
      getFormResponseByClientId(
        { sortField, sortDirection, ...paginationModel },
        [],
        clientId
      )
  );

  const handleViewDocument = (id: GridRowId) => {
    onViewDocument(Number(id));
  };

  const rowData: CombinedFormDocumentType[] = [];
  const defaultRowData: CombinedFormDocumentType[] = [];

  if (documentData) {
    documentData.map((dd) =>
      rowData.push({
        row_id: `doc-${dd.document_id}`,
        custom_form_response_id: null,
        form_name: null,
        custom_form_field_response: null,
        is_draft: null,
        status_id: dd.status_id,
        document_id: dd.document_id,
        client_id: dd.client_id,
        display_name: dd.display_name,
        comment: dd.comment,
        doc_type_ref_id: dd.doc_type_ref_id,
        created_by: dd.created_by,
        created_on: dd.created_on,
        reference: {
          label: dd.reference.label,
        },
        isDocument: true,
      })
    );
  }

  if (formsData) {
    formsData.data

      .filter((fd) => fd.status_id !== CUSTOM_FORM_STATUS_SUBMIT_ID)
      // 2) Then map the remaining items
      .forEach((fd) =>
        rowData.push({
          row_id: `custom_form-${fd.custom_form_response_id}`,
          custom_form_response_id: fd.custom_form_response_id,
          form_name: fd.form_name,
          custom_form_field_response: fd.custom_form_field_response,
          is_draft: fd.is_draft,
          status_id: fd.status_id,
          document_id: null,
          client_id: null,
          display_name: fd.form_name,
          comment: null,
          doc_type_ref_id: null,
          created_by: null,
          created_on: null,
          reference: {
            label: null,
          },
          isDocument: false,
        })
      );
  }

  const rows = defaultNullUndefined(rowData, defaultRowData);

  const columns: GridColDef[] = [
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        return params.row.status_id === CUSTOM_FORM_STATUS_NEW_ID ||
          params.row.status_id === CUSTOM_FORM_STATUS_DRAFT_ID ? (
          <Link to={`/documents/form/${params.id}`}>
            <DisplayDocumentStatus statusId={params.row.status_id} />
          </Link>
        ) : (
          <DisplayDocumentStatus statusId={params.row.status_id} />
        );
      },
      sortable: false,
      hideable: false,
      flex: 1,
      maxWidth: 300,
    },
    {
      field: "display_name",
      headerName: "Document Name",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "comment",
      headerName: "Comments",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "uploaded_by_client",
      headerName: "Uploaded By",
      sortable: false,
      hideable: false,
      maxWidth: 600,
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography>
            {params.row.uploaded_by_client ? "Client" : "Provider"}
          </Typography>
        );
      },
    },
    {
      field: " ",
      renderCell: (params) => (
        <>
          {!isArchived && (
            <>
              {(params.row.status_id === CUSTOM_FORM_STATUS_NEW_ID ||
                params.row.status_id === CUSTOM_FORM_STATUS_DRAFT_ID) &&
              !params.row.isDocument ? (
                <Tooltip title="Edit">
                  <Link
                    to={`/documents/form/${params.row.custom_form_response_id}`}
                  >
                    <IconButton>
                      <EditIcon color="action" />
                    </IconButton>
                  </Link>
                </Tooltip>
              ) : (
                <Tooltip title="View Document">
                  <IconButton
                    color="primary"
                    onClick={() =>
                      params.row.isDocument &&
                      handleViewDocument(params.row.document_id)
                    }
                  >
                    <RemoveRedEyeIcon color="primary" />
                  </IconButton>
                </Tooltip>
              )}

              {params.row.isDocument && (
                <Tooltip title="Download Document">
                  <a
                    href={buildAwsDownloadLink(
                      params.row.document_id.toString(),
                      clientId,
                      true
                    )}
                  >
                    <IconButton color="primary">
                      <DownloadIcon color="primary" />
                    </IconButton>
                  </a>
                </Tooltip>
              )}
            </>
          )}
        </>
      ),
      sortable: false,
      width: 150,
    },
  ];

  return (
    <>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <Box sx={{ maxHeight: 700, width: "100%", pt: 2, overflow: "auto" }}>
          <DataGrid
            getRowHeight={() => "auto"}
            autoHeight
            isRowSelectable={() => false}
            sx={{ fontSize: "medium" }}
            rows={rows}
            columns={columns}
            getRowId={(row) => row.row_id}
          />
        </Box>
      )}
    </>
  );
}
