import React from "react";
import {
  getFormattedDate,
  parseFloatSafe,
} from "../../../shared/globalFunctions";

/* --- Project Imports ---*/

/* --- MUI Imports ---*/
import { Box, Typography, Divider, Chip } from "@mui/material";

import { InvoicesQueryResponse } from "../../../apis/internalDb/billing/billing";
import {
  AUTH_PARTY_TYPE_SELF_ID,
  USDollar,
} from "../../../shared/resources/referenceConstants";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import InvoiceActionMenu from "../../../shared/ReusableComponents/InvoiceActionMenu";

interface InvoicesProps {
  invoiceData: InvoicesQueryResponse[];
  onCollectPayment: Function;
  onGeneratePDF: Function;
  onViewPaymentHistory: Function;
}

export default function Invoices({
  invoiceData,
  onCollectPayment,
  onGeneratePDF,
  onViewPaymentHistory,
}: InvoicesProps) {
  const PAGE_SIZE = [15, 50, 100];
  /* --- React Query ---*/

  /* --- Handlers ---*/

  const handleCollectPayment = (invoiceIds: number[], clientId: number) => {
    onCollectPayment(invoiceIds, clientId);
  };

  const handleGeneratePDF = (invoiceId: number, clientId: number) => {
    onGeneratePDF([invoiceId], clientId);
  };

  const handleViewPaymentHistory = (invoiceId: number) => {
    onViewPaymentHistory(invoiceId);
  };

  /* --- Other Stuff ---*/

  let columns: GridColDef[] = [
    {
      field: "invoice_date",
      headerName: "Invoice Date",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <>{getFormattedDate(new Date(params.row.invoice_date))}</>
      ),
    },
    {
      field: "auth_type_label",
      headerName: "Auth. Type",
      minWidth: 100,
      flex: 2,
    },
    {
      field: "auth_party_label",
      headerName: "Auth. Party Name",
      minWidth: 120,
      flex: 2,
    },
    {
      field: "total_client_amount_due",
      headerName: "Client Paid / Owed",
      minWidth: 150,
      flex: 2,
      renderCell: (params) => {
        let paid = parseFloatSafe(params.row.total_client_amount_paid, 0);
        let due = parseFloatSafe(params.row.total_client_amount_due, 0);
        let display = USDollar.format(paid) + " / " + USDollar.format(due);
        const color = due === 0 ? "success" : "error";
        return (
          <>
            <Chip label={display} color={color} />
          </>
        );
      },
    },
    {
      field: "total_insurance_amount_due",
      headerName: "Funding Source Paid / Owed",
      minWidth: 150,
      flex: 2,
      renderCell: (params) => {
        if (params.row.auth_party_type === AUTH_PARTY_TYPE_SELF_ID)
          return <></>;

        let paid = parseFloatSafe(params.row.total_insurance_amount_paid, 0);
        let due = parseFloatSafe(params.row.total_insurance_amount_due, 0);
        let display = USDollar.format(paid) + " / " + USDollar.format(due);
        const color = due === 0 ? "success" : "error";
        return (
          <>
            <Chip label={display} color={color} />
          </>
        );
      },
    },
    {
      field: "total_client_copay_due",
      headerName: "Copay Paid / Owed",
      minWidth: 150,
      flex: 2,
      renderCell: (params) => {
        let paid = parseFloatSafe(params.row.total_client_copay_paid, 0);
        let due = parseFloatSafe(params.row.total_client_copay_due, 0);
        let display = USDollar.format(paid) + " / " + USDollar.format(due);
        const color = due === 0 ? "success" : "error";
        return (
          <>
            <Chip label={display} color={color} />
          </>
        );
      },
    },
    {
      field: "total_written_off",
      headerName: "Total Write Off",
      minWidth: 80,
      flex: 1,
      renderCell: (params) => (
        <>
          {USDollar.format(
            parseFloatSafe(params.row.total_client_written_off, 0) +
              parseFloatSafe(params.row.total_insurance_written_off, 0)
          )}
        </>
      ),
    },
    {
      field: "total_amount",
      headerName: "Total Invoiced",
      minWidth: 80,
      flex: 1,
      renderCell: (params) => (
        <>
          <strong>
            {USDollar.format(
              parseFloatSafe(params.row.original_client_amount_due, 0) +
                parseFloatSafe(params.row.original_funding_source_amount_due, 0)
            )}
          </strong>
        </>
      ),
    },
    {
      field: "total_due",
      headerName: "Remainder Due",
      minWidth: 80,
      flex: 1,
      renderCell: (params) => (
        <>
          <strong>
            {USDollar.format(parseFloatSafe(params.row.total_due, 0))}
          </strong>
        </>
      ),
    },
    {
      field: "actions",
      type: "actions",
      align: "left",
      renderCell: (params) => (
        <>
          <InvoiceActionMenu
            invoiceId={Number(params.id)}
            onGeneratePDF={() =>
              handleGeneratePDF(params.row.invoice_id, params.row.client_id)
            }
            onCollectPayment={() =>
              handleCollectPayment(
                [params.row.invoice_id],
                params.row.client_id
              )
            }
            showDetails={true}
            showGenerate={true}
            showCollectPayment={
              !params.row.is_paid &&
              parseFloatSafe(params.row.total_client_amount_due, 0) > 0
            }
            onViewPaymentHistory={() => {
              handleViewPaymentHistory(params.row.invoice_id);
            }}
          />
        </>
      ),
      sortable: false,
      width: 150,
    },
  ];

  return (
    <Box mt={2}>
      <Typography variant="h6">{`Invoices (${invoiceData.length})`}</Typography>
      <Divider />
      <Box mt={2}>
        <DataGrid
          rows={invoiceData}
          columns={columns}
          pageSizeOptions={PAGE_SIZE}
          autoHeight
          getRowId={(row) => row.invoice_id}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "break-spaces",
              lineHeight: 1,
            },
            "&.MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer":
              {
                pl: 1,
              },
            fontSize: 14,
          }}
        />
      </Box>
    </Box>
  );
}
