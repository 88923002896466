import React from "react";
import { useQuery } from "react-query";

/* --- MUI Imports --- */
import { Box, LinearProgress } from "@mui/material";

/* --- Project Imports --- */
import DocumentForm from "./DocumentForm";
import {
  clientPortalFormKeys,
  FormResponseType,
  getFormResponseById,
} from "../../../apis/internalDb/forms/forms";
import DataRetrievalError from "../../../shared/ReusableComponents/DataRetrievalError";
import { useNavigate, useParams } from "react-router-dom";
import {
  defaultNullUndefined,
  parseIntSafe,
} from "../../../shared/globalFunctions";
import {
  CUSTOM_FORM_STATUS_DRAFT_ID,
  CUSTOM_FORM_STATUS_NEW_ID,
} from "../../../shared/resources/referenceConstants";
import { selectSelectedClient } from "../../../state/slices/access";
import { useAppSelector } from "../../../state/hooks";

export default function DocumentFormParent() {
  const params = useParams();
  const navigate = useNavigate();
  const selectedClient = useAppSelector(selectSelectedClient);
  const documentId = parseIntSafe(params.id, 0);
  const {
    isLoading: formsLoading,
    isError: formsError,
    data: formsData,
  } = useQuery<FormResponseType, Error>(
    clientPortalFormKeys.detail(documentId),
    () =>
      getFormResponseById(
        documentId,
        defaultNullUndefined(selectedClient?.clientId, 0)
      )
  );

  const handleClose = () => {
    navigate("/");
  };

  if (formsLoading || selectedClient === undefined) {
    return <LinearProgress />;
  } else if (formsData === undefined || formsError) {
    return <DataRetrievalError />;
  } else {
    const isEdit =
      formsData.status_id === CUSTOM_FORM_STATUS_DRAFT_ID ||
      formsData.status_id === CUSTOM_FORM_STATUS_NEW_ID;
    return (
      <Box marginLeft={2} marginRight={2}>
        <DocumentForm
          formData={formsData}
          isEdit={isEdit}
          onClose={handleClose}
          selectedClientId={defaultNullUndefined(selectedClient?.clientId, 0)}
        />
      </Box>
    );
  }
}
