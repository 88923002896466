/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { lazy } from "react";
import {
  defaultNullUndefined,
  getSuccessMessage,
  parseFloatSafe,
} from "../../shared/globalFunctions";
import { useAppSelector } from "../../state/hooks";
import { selectSelectedClient } from "../../state/slices/access";

/* --- Project Imports ---*/

/* --- MUI Imports ---*/
import {
  Box,
  Tab,
  Tabs,
  Typography,
  LinearProgress,
  Stack,
  Button,
  Grid,
} from "@mui/material";

import { useQuery, useQueryClient } from "react-query";
import {
  InvoiceDataSet,
  InvoicesQueryResponse,
  billingKeys,
  getInvoiceData,
} from "../../apis/internalDb/billing/billing";
import { clientBillingKeys } from "../../apis/internalDb/keyFactory";
import DataRetrievalError from "../../shared/ReusableComponents/DataRetrievalError";
import { USDollar } from "../../shared/resources/referenceConstants";
import Invoices from "./tabs/Invoices";

import TabPanel from "../../shared/ReusableComponents/TabPanel";
import PaymentHistoryParent from "./tabs/PaymentHistoryParent";
import ConfirmationDialog from "../../shared/ReusableComponents/ConfirmationDialog";

import SuccessMessage from "../../shared/ReusableComponents/SuccessMessage";
import ShowPaymentDetailsDialog from "./paymentHistory/ShowPaymentDetailsDialog";
import InvoicePDFParent from "../../pdfGeneration/invoice/InvoicePDFParent";
import { DateFilterProps } from "../../shared/globalConstants";
import dayjs from "dayjs";
const PaymentMethodsParent = lazy(
  () => import("./paymentMethods/PaymentMethodsParent")
);
const CollectPaymentParent = lazy(
  () => import("./stripe/CollectPaymentParent")
);

export default function BillingParent() {
  const client = useAppSelector(selectSelectedClient);
  const selectedClientId = defaultNullUndefined(client?.clientId, 0);
  const mapClientId = defaultNullUndefined(client?.mapClientId, 0);
  let totalAmountDue: number = 0;
  const [tabIndex, setTabIndex] = React.useState<number>(0);
  const [confirmClose, setConfirmClose] = React.useState<boolean>(false);
  const [paymentIntentId, setPaymentIntentId] = React.useState<string | null>(
    null
  );
  const [isServerError, setIsServerError] = React.useState(false);
  const [serverErrorMessage, setServerErrorMessage] = React.useState<
    string | undefined
  >("");
  const [successState, setSuccessState] = React.useState<string | null>(null);
  const [invoiceIds, setInvoiceIds] = React.useState<number[]>([0]);
  const [isCollectPayment, setIsCollectPayment] =
    React.useState<boolean>(false);
  const today = dayjs();
  const defaultDateFilter: DateFilterProps = {
    startDate: today.startOf("year"),
    endDate: today,
  };
  const [dateFilterModel, setDateFilterModel] =
    React.useState<DateFilterProps>(defaultDateFilter);

  const PAGE_SIZE = [15, 50, 100];
  const [viewPaymentHistory, setViewPaymentHistory] =
    React.useState<boolean>(false);
  const [showPDF, setShowPDF] = React.useState<boolean>(false);
  const [sortField, setSortField] = React.useState<string | undefined>(
    undefined
  );
  const [sortDirection, setSortDirection] = React.useState<string | undefined>(
    undefined
  );
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: PAGE_SIZE[0],
  });

  /* --- React Query ---*/
  const queryClient = useQueryClient();
  const {
    isLoading,
    data: invoiceData,
    error,
    isError,
  } = useQuery<InvoiceDataSet, Error>(
    billingKeys.invoiceTable(
      { sortField, sortDirection, ...paginationModel },
      selectedClientId,
      dateFilterModel,
      ""
    ),
    () =>
      getInvoiceData(
        { sortField, sortDirection, ...paginationModel },
        [selectedClientId],
        dateFilterModel,
        defaultNullUndefined(client?.orgId, 0),
        "",
        -1,
        -1,
        ""
      )
  );
  /* --- Handlers ---*/
  const handleCloseSuccessMessage = () => {
    setSuccessState(null);
  };

  const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex);
  };

  const handleCancelCollectPayment = (paymentIntentId: string) => {
    setPaymentIntentId(paymentIntentId);
    setConfirmClose(true);
  };

  const handleCollectPaymentSuccess = () => {
    setPaymentIntentId(paymentIntentId);
    setIsCollectPayment(false);
    queryClient.invalidateQueries({
      queryKey: clientBillingKeys.all,
      refetchInactive: true,
    });
  };

  const handleCollectPayment = (invoiceIds: number[], clientId: number) => {
    setInvoiceIds(invoiceIds);
    setIsCollectPayment(true);
  };

  const handleGeneratePDF = (invoiceId: number, clientId: number) => {
    setShowPDF(true);
    setInvoiceIds([invoiceId]);
  };

  const handleViewPaymentHistory = (invoiceId: number) => {
    setInvoiceIds([invoiceId]);
    setViewPaymentHistory(true);
  };

  const handleCancelViewPDF = () => {
    setShowPDF(false);
  };

  const handleConfirmClose = async () => {
    try {
      setConfirmClose(false);
      setIsCollectPayment(false);
    } catch (error: any) {
      console.error(error);
    }
  };

  const handleCancelClose = () => {
    setConfirmClose(false);
  };

  /* --- Other Stuff ---*/
  if (invoiceData) {
    totalAmountDue = invoiceData.data.reduce(
      (total: number, currentValue: InvoicesQueryResponse) =>
        total + parseFloatSafe(currentValue.total_client_amount_due, 0),
      0
    );
  }

  /* --- Render ---*/
  if (isLoading || client === undefined) {
    return <LinearProgress />;
  } else if (isError || invoiceData === undefined || client === null) {
    return (
      <DataRetrievalError
        additionalMessage={error?.message}
        showDefaultMessage={false}
      />
    );
  } else {
    return (
      <Box>
        <Grid container direction="row">
          <Grid xs={1} item></Grid>
          <Grid xs={10} item>
            <Typography variant="h3">Billing</Typography>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mb={2}
              sx={{
                width: "30%",
                height: "80px",
                backgroundColor: "#f0f0f0",
                padding: "1rem",

                margin: "0 auto",
              }}
            >
              <Stack direction={"row"} spacing={2} alignItems={"center"}>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold" }}
                >{`Total Balance ${USDollar.format(
                  totalAmountDue
                )}`}</Typography>
                <Button
                  onClick={() => handleCollectPayment([], mapClientId)}
                  variant="contained"
                  color="success"
                  disabled={totalAmountDue === 0}
                >
                  Pay Now
                </Button>
              </Stack>
            </Box>
            {isServerError && (
              <div style={{ marginTop: 5, marginBottom: 5 }}>
                <DataRetrievalError additionalMessage={serverErrorMessage} />
              </div>
            )}
            <SuccessMessage
              open={!!successState}
              onClose={handleCloseSuccessMessage}
              alertMessage={getSuccessMessage(successState)}
            />
            <Tabs value={tabIndex} onChange={handleTabChange}>
              <Tab label="Invoices" />
              <Tab label="Payment Methods" />
              <Tab label="Payment History" />
            </Tabs>

            <TabPanel value={tabIndex} index={0} padding={0}>
              <Invoices
                invoiceData={invoiceData.data}
                onCollectPayment={handleCollectPayment}
                onGeneratePDF={handleGeneratePDF}
                onViewPaymentHistory={handleViewPaymentHistory}
              />
            </TabPanel>

            <TabPanel value={tabIndex} index={1} padding={0}>
              <PaymentMethodsParent
                mapOrgToClientId={mapClientId}
                orgProfileId={client.orgId}
                selectedClientId={selectedClientId}
              />
            </TabPanel>

            <TabPanel value={tabIndex} index={2} padding={0}>
              <PaymentHistoryParent
                mapOrgToClientId={mapClientId}
                selectedClientId={selectedClientId}
              />
            </TabPanel>
            {isCollectPayment && (
              <CollectPaymentParent
                onCancel={handleCancelCollectPayment}
                mapOrgToClientId={mapClientId}
                orgProfileId={client.orgId}
                onSuccess={handleCollectPaymentSuccess}
                onConfirmClose={handleConfirmClose}
                selectedClientId={selectedClientId}
                balanceDue={totalAmountDue}
                invoiceIds={invoiceIds}
              />
            )}
            <InvoicePDFParent
              invoiceIds={invoiceIds}
              clientId={mapClientId}
              onCancel={handleCancelViewPDF}
              showDialog={showPDF}
            />
            {viewPaymentHistory && (
              <ShowPaymentDetailsDialog
                invoiceId={invoiceIds[0]}
                paymentId={null}
                onClose={() => setViewPaymentHistory(false)}
                selectedClientId={selectedClientId}
              />
            )}
            {confirmClose && (
              <ConfirmationDialog
                isOpen={confirmClose}
                title="Warning"
                message="Are you sure you would like to cancel this action?"
                onCancel={handleCancelClose}
                onConfirm={handleConfirmClose}
              />
            )}
          </Grid>
          <Grid xs={1} item></Grid>
        </Grid>
      </Box>
    );
  }
}
