/* --- Interfaces -- */
export interface RefDataInterface {
  ref_id: number;
  label: string;
  label_abbr: string;
  ref_type: string;
}

export interface RefDataObjectInterface {
  [refType: string]: RefDataInterface[];
}

/* --- Data Manipulation Functions --- */
export const indexRefType = (
  refData?: RefDataObjectInterface,
  refType?: string
) => {
  if (refData == null || refType == null) {
    return [] as RefDataInterface[];
  } else {
    return refData[refType] as RefDataInterface[];
  }
};

/* --- Currency Formatter --- */
export let USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  currencySign: "accounting",
});

/* --- Constants --- */
export const US_STATE_REF_TYPE = "US_STATE";
export const GENDER_ID_REF_TYPE = "GENDER_IDENTITY";
export const CLIENT_RACE_REF_TYPE = "CLIENT_RACE";
export const APPT_TYPE_INIT_CONSULT_ID = 110;
export const SERVICE_RATE_TIMED_CODE_ID = 111;
export const SERVICE_RATE_ITEM_ID = 113;
export const TIMED_CODE_HOUR_ID = 115;
export const TIMED_CODE_MINUTES_ID = 116;
export const DOC_TYPE_REF_TYPE = "DOCUMENT_TYPE";
export const DOC_TYPE_MISC_ID = 153;
export const DOC_TYPE_CLIENT_UPLOAD_ID = 228;
export const CUSTOM_FORM_STATUS_NEW_ID = 158;
export const CUSTOM_FORM_STATUS_DRAFT_ID = 159;
export const CUSTOM_FORM_STATUS_SUBMIT_ID = 160;
export const CUSTOM_FORM_STATUS_APPROVED_ID = 161;
export const CUSTOM_FORM_STATUS_UPLOADED_ID = 234;
export const PAYMENT_REF_TYPE = "PAYMENT_TYPE";
export const PAYMENT_TYPE_CARD_ID = 164;
export const PAYMENT_TYPE_OTHER_ID = 166;
export const AUTH_PARTY_TYPE_SELF_ID = 192;
export const INVOICE_ITEM_SERVICE_ID = 212;
export const INVOICE_ITEM_PRODUCT_ID = 213;
