import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import ClientPortalFormDataTable from "./ClientPortalFormDataTable";

interface DocumentsListProps {
  clientId: number;
  formResponseStatusIds: number[];
  onViewDocument: Function;
}
export default function DocumentsList({
  clientId,
  formResponseStatusIds,
  onViewDocument,
}: DocumentsListProps) {
  const handleViewDocument = (id: number) => {
    onViewDocument(id);
  };
  return (
    <Box>
      <Grid container direction="row">
        <Grid xs={1} item></Grid>
        <Grid xs={10} item>
          <Typography variant="h3">Documents to Complete</Typography>
          <ClientPortalFormDataTable
            clientId={clientId}
            formResponseStatusIds={formResponseStatusIds}
            onViewDocument={handleViewDocument}
          />
        </Grid>
        <Grid xs={1} item></Grid>
      </Grid>
    </Box>
  );
}
