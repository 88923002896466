import {
  defaultNullUndefined,
  toStringSafe,
} from "../../../shared/globalFunctions";
import { DOC_TYPE_MISC_ID } from "../../../shared/resources/referenceConstants";
import internalConnection from "../internalConnection";

const CLIENT_DOCUMENT_PATH = "client";

export const generateAwsDocumentPath = (
  clientId: number,
  docRefTypeId: number | null
) => {
  const documentRefType = toStringSafe(
    defaultNullUndefined(docRefTypeId, DOC_TYPE_MISC_ID)
  );
  const unixTimeKey = new Date().getTime();
  return `${CLIENT_DOCUMENT_PATH}/${toStringSafe(
    clientId
  )}/${documentRefType}/${unixTimeKey.toString()}/`;
};

export interface AWSFilePost {
  file: File;
  path?: string;
  clientId?: number;
  docRefTypeId?: number;
  fileName: string;
  comment: string | undefined;
}

export const postAwsFile = async (documentInfo: AWSFilePost) => {
  const formData = new FormData();
  // const blob = await streamToBlob(documentInfo.file);

  formData.append("files", documentInfo.file);
  if (documentInfo.clientId) {
    formData.append("clientId", documentInfo.clientId.toString());
  }

  formData.append("fileName", documentInfo.fileName);

  if (documentInfo.docRefTypeId) {
    formData.append("docRefTypeId", documentInfo.docRefTypeId.toString());
  }

  if (documentInfo.comment) {
    formData.append("comment", documentInfo.comment);
  }

  if (documentInfo.path) formData.append("path", documentInfo.path);
  const response = await internalConnection.post(
    "filemanagement/aws/awsPostClientFile",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data.results as string[];
};

export const buildAwsDownloadLink = (
  documentId: string | undefined,
  selectedClientId: number,
  getAsBlob: boolean
) => {
  const uriEncodedDocumentId = encodeURI(documentId ? documentId : "");
  return (
    `/api/1.0/portals/client/documents/${selectedClientId}/getClientDocument?documentId=` +
    uriEncodedDocumentId +
    `&getAsBlob=${getAsBlob}`
  );
};
